<template>
  <div class="extradatamatch">
    <div class="extradatamatch__sep"></div>
    <div
      class="extradatamatch__cont-goals"
      v-if="data.home_goals_minutes.length > 0 || data.visiting_goals_minutes.length > 0"
    >
      <div class="extradatamatch__cont-goals__title-movil"><img src="/images/ball-calendar.png" />Goles</div>
      <div class="extradatamatch__cont-goals__cont-team-info">
        <div class="extradatamatch__cont-goals__cont-team-info__home-cont">
          <template v-for="(goal, key) in data.home_goals_minutes">
            <div :key="key + 'gh'" class="extradatamatch__cont-goals__cont-team-info__home-cont__item">
              <img src="/images/ball-calendar.png" />
              <div class="extradatamatch__cont-goals__cont-team-info__home-cont__item__name">{{ goal }}</div>
              <!-- <div class="extradatamatch__cont-goals__cont-team-info__home-cont__item__time">20’</div> -->
            </div>
          </template>
        </div>
        <div class="extradatamatch__cont-goals__cont-team-info__away-cont">
          <template v-for="(goal, key) in data.visiting_goals_minutes">
            <div :key="key + 'gv'" class="extradatamatch__cont-goals__cont-team-info__away-cont__item">
              <!-- <div class="extradatamatch__cont-goals__cont-team-info__away-cont__item__time">20’</div> -->
              <div class="extradatamatch__cont-goals__cont-team-info__away-cont__item__name">{{ goal }}</div>
              <img src="/images/ball-calendar.png" />
            </div>
          </template>
        </div>
      </div>
    </div>
    <div
      class="extradatamatch__cont-yellow-cards"
      :class="{ noborder: data.home_red_cards_minutes.length == 0 && data.away_red_cards_minutes.length == 0 }"
      v-if="data.home_yellow_cards_minutes.length > 0 || data.away_yellow_cards_minutes.length > 0"
    >
      <div class="extradatamatch__cont-yellow-cards__title-movil">
        <img src="/images/yellow-card-calendar.png" />T. Amarillas
      </div>
      <div class="extradatamatch__cont-yellow-cards__cont-team-info">
        <div class="extradatamatch__cont-yellow-cards__cont-team-info__home-cont">
          <template v-for="(yellowcard, key) in data.home_yellow_cards_minutes">
            <div :key="key + 'yh'" class="extradatamatch__cont-yellow-cards__cont-team-info__home-cont__item">
              <img src="/images/yellow-card-calendar.png" />
              <div class="extradatamatch__cont-yellow-cards__cont-team-info__home-cont__item__name">
                {{ yellowcard }}
              </div>
              <!-- <div class="extradatamatch__cont-yellow-cards__cont-team-info__home-cont__item__time">20’</div> -->
            </div>
          </template>
        </div>
        <div class="extradatamatch__cont-yellow-cards__cont-team-info__away-cont">
          <template v-for="(yellowcard, key) in data.away_yellow_cards_minutes">
            <div :key="key + 'yv'" class="extradatamatch__cont-yellow-cards__cont-team-info__away-cont__item">
              <!-- <div class="extradatamatch__cont-yellow-cards__cont-team-info__away-cont__item__time">20’</div> -->
              <div class="extradatamatch__cont-yellow-cards__cont-team-info__away-cont__item__name">
                {{ yellowcard }}
              </div>
              <img src="/images/yellow-card-calendar.png" />
            </div>
          </template>
        </div>
      </div>
    </div>

    <div
      class="extradatamatch__cont-red-cards"
      v-if="data.home_red_cards_minutes.length > 0 || data.away_red_cards_minutes.length > 0"
    >
      <div class="extradatamatch__cont-red-cards__title-movil"><img src="/images/red-card-calendar.png" />T. Rojas</div>
      <div class="extradatamatch__cont-red-cards__cont-team-info">
        <div class="extradatamatch__cont-red-cards__cont-team-info__home-cont">
          <template v-for="(redcard, key) in data.home_red_cards_minutes">
            <div :key="key + 'rh'" class="extradatamatch__cont-red-cards__cont-team-info__home-cont__item">
              <img src="/images/red-card-calendar.png" />
              <div class="extradatamatch__cont-red-cards__cont-team-info__home-cont__item__name">{{ redcard }}</div>
              <!-- <div class="extradatamatch__cont-red-cards__cont-team-info__home-cont__item__time">20’</div> -->
            </div>
          </template>
        </div>
        <div class="extradatamatch__cont-red-cards__cont-team-info__away-cont">
          <template v-for="(redcard, key) in data.away_red_cards_minutes">
            <div :key="key + 'rv'" class="extradatamatch__cont-red-cards__cont-team-info__away-cont__item">
              <!-- <div class="extradatamatch__cont-red-cards__cont-team-info__away-cont__item__time">20’</div> -->
              <div class="extradatamatch__cont-red-cards__cont-team-info__away-cont__item__name">{{ redcard }}</div>
              <img src="/images/red-card-calendar.png" />
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ExtraDataMatch',
  components: {},
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style lang="scss">
.noborder {
  border-bottom: 0px !important;
}
.extradatamatch {
  width: 100%;
  height: auto;
  display: inline-block;
  margin-bottom: 0px;
  @media screen and (max-width: 500px) {
    margin-bottom: 15px;
  }
  &__sep {
    width: 97%;
    height: 2px;
    border-top: 1px dotted #afbbcd;
    margin: 0 auto;
    margin-top: 5px;
    display: inline-block;
  }

  &__cont-goals {
    width: 82%;
    height: auto;
    margin: 0 auto;
    display: block;
    margin-top: 20px;
    margin-bottom: 5px;
    border-bottom: 1px dotted #afbbcd;
    @media screen and (max-width: 500px) {
      margin-top: 0px;
    }
    &__title-movil {
      width: 100%;
      height: 24px;
      display: none;
      justify-content: center;
      align-content: center;
      align-items: center;
      font-family: 'Avenir-Pro-Bold';
      font-size: 12px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.92;
      letter-spacing: -0.24px;
      text-align: left;
      color: #132739;
      @media screen and (max-width: 500px) {
        display: inline-flex;
      }
    }
    &__cont-team-info {
      width: 100%;
      height: auto;
      display: inline-flex;
      margin-bottom: 10px;

      &__home-cont {
        width: 50%;
        height: auto;
        &__item {
          width: 100%;
          height: 16px;
          float: left;
          display: inline-flex;
          justify-content: left;
          margin-top: 7px;
          align-content: center;
          overflow: hidden;
          align-items: center;
          &__name {
            font-size: 14px;
            font-family: 'Avenir-Medium';
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 0.79;
            letter-spacing: -0.28px;
            text-align: left;
            color: #132739;
            margin-left: 10px;
            @media screen and (max-width: 500px) {
              margin-left: 0px;
              font-size: 12px;
            }
          }
          &__time {
            font-family: 'Avenir-Demi';
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 0.79;
            letter-spacing: -0.28px;
            text-align: left;
            color: #132739;
            margin-left: 10px;
            @media screen and (max-width: 500px) {
              font-size: 12px;
            }
          }
          img {
            @media screen and (max-width: 500px) {
              display: none;
            }
          }
          &:first-child {
            margin-top: 0px;
          }
        }
      }
      &__away-cont {
        width: 50%;
        height: auto;
        &__item {
          width: 100%;
          height: 16px;
          float: right;
          display: inline-flex;
          justify-content: left;
          margin-top: 7px;
          align-content: center;
          overflow: hidden;
          align-items: center;
          justify-content: right;
          &__name {
            font-size: 14px;
            font-family: 'Avenir-Medium';
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 0.79;
            letter-spacing: -0.28px;
            text-align: left;
            color: #132739;
            margin-right: 10px;
            @media screen and (max-width: 500px) {
              margin-right: 0px;
              font-size: 12px;
            }
          }
          &__time {
            font-family: 'Avenir-Demi';
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 0.79;
            letter-spacing: -0.28px;
            text-align: left;
            color: #132739;
            margin-right: 10px;
            @media screen and (max-width: 500px) {
              font-size: 12px;
            }
          }
          img {
            @media screen and (max-width: 500px) {
              display: none;
            }
          }
          &:first-child {
            margin-top: 0px;
          }
        }
      }
    }
  }
  &__cont-yellow-cards {
    width: 82%;
    height: auto;
    margin: 0 auto;
    display: inline-block;
    margin-bottom: 5px;
    border-bottom: 1px dotted #afbbcd;
    &__title-movil {
      width: 100%;
      height: 24px;
      display: none;
      justify-content: center;
      align-content: center;
      align-items: center;
      font-size: 12px;
      font-family: 'Avenir-Pro-Bold';
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.92;
      letter-spacing: -0.24px;
      text-align: left;
      color: #132739;
      @media screen and (max-width: 500px) {
        display: inline-flex;
      }
    }
    &__cont-team-info {
      width: 100%;
      height: auto;
      display: inline-flex;
      justify-content: center;
      align-content: center;
      margin-bottom: 5px;
      align-items: center;
      &__home-cont {
        width: 100%;
        height: auto;
        &__item {
          width: 100%;
          height: 16px;
          float: left;
          display: inline-flex;
          justify-content: left;
          margin-top: 7px;
          align-content: center;
          overflow: hidden;
          align-items: center;
          &__name {
            font-size: 14px;
            font-family: 'Avenir-Medium';
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 0.79;
            letter-spacing: -0.28px;
            text-align: left;
            color: #132739;
            margin-left: 10px;
            @media screen and (max-width: 500px) {
              margin-left: 0px;
              font-size: 12px;
            }
          }
          &__time {
            font-family: 'Avenir-Demi';
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 0.79;
            letter-spacing: -0.28px;
            text-align: left;
            color: #132739;
            margin-left: 10px;
            @media screen and (max-width: 500px) {
              font-size: 12px;
            }
          }
          img {
            @media screen and (max-width: 500px) {
              display: none;
            }
          }
          &:first-child {
            margin-top: 0px;
          }
        }
      }
      &__away-cont {
        width: 100%;
        height: auto;
        &__item {
          width: 100%;
          height: 16px;
          float: right;
          display: inline-flex;
          justify-content: left;
          margin-top: 7px;
          align-content: center;
          overflow: hidden;
          align-items: center;
          justify-content: right;
          &__name {
            font-size: 14px;
            font-family: 'Avenir-Medium';
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 0.79;
            letter-spacing: -0.28px;
            text-align: left;
            color: #132739;
            margin-right: 10px;
            @media screen and (max-width: 500px) {
              margin-right: 0px;
              font-size: 12px;
            }
          }
          &__time {
            font-family: 'Avenir-Demi';
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 0.79;
            letter-spacing: -0.28px;
            text-align: left;
            color: #132739;
            margin-right: 10px;
            @media screen and (max-width: 500px) {
              font-size: 12px;
            }
          }
          img {
            @media screen and (max-width: 500px) {
              display: none;
            }
          }
          &:first-child {
            margin-top: 0px;
          }
        }
      }
    }
  }
  &__cont-red-cards {
    width: 82%;
    height: auto;
    margin: 0 auto;
    display: inline-block;
    margin-bottom: 10px;
    &__title-movil {
      width: 100%;
      height: 24px;
      display: none;
      justify-content: center;
      align-content: center;
      font-family: 'Avenir-Pro-Bold';
      align-items: center;
      font-size: 12px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.92;
      letter-spacing: -0.24px;
      text-align: left;
      color: #132739;
      @media screen and (max-width: 500px) {
        display: inline-flex;
      }
    }
    &__cont-team-info {
      width: 100%;
      height: auto;
      display: inline-flex;
      margin-bottom: 5px;
      justify-content: center;
      align-content: center;
      align-items: center;
      &__home-cont {
        width: 100%;
        height: auto;
        &__item {
          width: 100%;
          height: 16px;
          float: left;
          display: inline-flex;
          justify-content: left;
          margin-top: 7px;
          align-content: center;
          overflow: hidden;
          align-items: center;
          &__name {
            font-size: 14px;
            font-family: 'Avenir-Medium';
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 0.79;
            letter-spacing: -0.28px;
            text-align: left;
            color: #132739;
            margin-left: 10px;
            @media screen and (max-width: 500px) {
              margin-left: 0px;
              font-size: 12px;
            }
          }
          &__time {
            font-family: 'Avenir-Demi';
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 0.79;
            letter-spacing: -0.28px;
            text-align: left;
            color: #132739;
            margin-left: 10px;
            @media screen and (max-width: 500px) {
              font-size: 12px;
            }
          }
          img {
            @media screen and (max-width: 500px) {
              display: none;
            }
          }
          &:first-child {
            margin-top: 0px;
          }
        }
      }
      &__away-cont {
        width: 100%;
        height: auto;
        &__item {
          width: 100%;
          height: 16px;
          float: right;
          display: inline-flex;
          justify-content: left;
          margin-top: 7px;
          align-content: center;
          overflow: hidden;
          align-items: center;
          justify-content: right;
          &__name {
            font-size: 14px;
            font-family: 'Avenir-Medium';
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 0.79;
            letter-spacing: -0.28px;
            text-align: left;
            color: #132739;
            margin-right: 10px;
            @media screen and (max-width: 500px) {
              margin-right: 0px;
              font-size: 12px;
            }
          }
          &__time {
            font-family: 'Avenir-Demi';
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 0.79;
            letter-spacing: -0.28px;
            text-align: left;
            color: #132739;
            margin-right: 10px;
            @media screen and (max-width: 500px) {
              font-size: 12px;
            }
          }
          img {
            @media screen and (max-width: 500px) {
              display: none;
            }
          }
          &:first-child {
            margin-top: 0px;
          }
        }
      }
    }
  }
}
</style>
